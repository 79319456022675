<template>
  <div>
    <div class="content-detail-privilege">
      <div class="text-center">
        <img
          :src="detail.detail.image_url"
          v-if="detail.detail.image_url"
          class="w-100"
        />
        <img
          :src="require('@/assets/images/default-image.png')"
          v-else
          class="w-100"
        />
      </div>
      <div class="p-2" style="background-color: #3a3a3a">
        <div class="title-detail line-clamp-2">
          {{ detail.detail.name }}
        </div>
        <b-row>
          <b-col>
            <div class="valide-until">
              วันหมดอายุ:
              {{
                detail.detail.end_datetime
                  ? dateFormat(detail.detail.end_datetime)
                  : "-"
              }}
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="privilege-box-detail">
        <div class="description">
          <div class="sub-description">รายละเอียด</div>
          <div
            class="p-3"
            v-if="detail.detail.description"
            v-html="detail.detail.description"
          ></div>
          <div class="p-3" v-else>-</div>
        </div>
      </div>
    </div>
    <b-row class="footer">
      <div class="text-center w-100">
        <b-button
          class="w-100 btn-black"
          :disabled="detail.detail.status === 0"
          @click="openQR()"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          >แสดงคูปอง</b-button
        >
      </div>
    </b-row>
    <ModalQR
      ref="ModalQR"
      :name="detail.detail.name"
      :code="detail.detail.prefix"
    />
  </div>
</template>

<script>
import ModalQR from "@/components/privilege/modal/ModalQR";
import moment from "moment";
export default {
  components: {
    ModalQR
  },
  props: {
    detail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    openQR() {
      this.$refs.ModalQR.show();
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.description {
  background-color: #fff;
}
.sub-description {
  padding: 10px;
  border-bottom: 1px solid gray;
  font-weight: 600;
}
// .btn-black {
//   background-color: #000;
// }
.status-reward {
  color: lightgreen;
  font-weight: 600;
  font-size: 14px;
  text-align: right;
}
.title-detail {
  color: #fff;
  font-size: 18px;
  margin: 5 0px;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.valide-until {
  color: #fff;
  font-size: 14px;
}
.receive-point {
  color: #fff;
  text-align: right;
  font-size: 14px;
}
.privilege-box-detail {
  background-color: #fff;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  font-weight: 600;
  border-bottom: 4px solid #000;
}
::v-deep a {
  color: gray;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 15px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-redeem {
  position: fixed;
  width: 90%;
  bottom: 10px;
  left: 20px;
  right: 10px;
  border: none;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}
.content-detail-privilege {
  margin: 1rem;
}
.my-20px {
  padding-bottom: 80px;
}
::v-deep p img {
  width: 100% !important;
  height: auto !important;
}
::v-deep img {
  width: 100% !important;
  height: auto !important;
}
::v-deep table {
  width: 100% !important;
  height: auto !important;
}
::v-deep tr {
  height: auto !important;
}
::v-deep td {
  height: auto !important;
}
</style>

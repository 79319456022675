<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="QRModal"
      ref="QRModal"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div>
        <div class="m-auto text-center p-3 bg-white header-qr line-clamp-2"> 
          {{name}}
        </div>
      </div>
      <div class="text-center p-3">
        <div class="font-weight-bold f-size-18">
            รหัสคูปอง : {{code ? code : '-'}}
        </div>
        <div>
            <div class="dot dot-left" style="left:0; z-index: 1;"></div>
            <div class="border-behind"></div>
            <div class="dot dot-right" style="right:0;"></div>
        </div>
        <div>
          <vue-qrcode class="qr-width-70" :value="code" />
        </div>
        <!-- <div>
            ใช้ภายใน 
            <countdown :time="600000" :transform="transform">
                <template slot-scope="props">{{ props.minutes }}:{{ props.seconds }}</template>
            </countdown>
            นาที
        </div> -->
        <div>แสดงรหัสและรับสิทธิพิเศษที่ Hourglass ทุกสาขา</div>
      </div>
      <div class="border-top p-3 text-center btn-confirm-popup">
        <div @click="close">ปิดหน้าต่างนี้</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
export default {
    props:{
      code:{
        required:true,
        type:String
      },
      name:{
        required:false,
        type:String
      }
    },
    components:{
        VueQrcode
    },
    data() {
        return {
            modalShow: false,
            countDownTime : 10,
            countdown:10
        };
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        close() {
            this.modalShow = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
.btn-confirm-popup{
  color: #fff!important;
  background-color: black;
}
.dot {
    display: inline-block;
    top: 6%;
    position: absolute;
    width: 20px;
    height: 45px;
    border: 10px solid #000;
    border-left: 0;
    background-color: #000;
}
.dot-left{ 
    left: 0;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
}
.dot-right{
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    right: 0;
}
.border-behind{
    border-top: 4px dashed #dfdfdf;
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0;
}
.line-clamp-2{
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
}
.qr-width-70{
    width: 80%;
}
</style>

<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
      <PrivilegeDetailPanel :detail="privilegeDetail" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import PrivilegeDetailPanel from "@/components/privilege/detail/PrivilegeDetailPanel";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    PrivilegeDetailPanel,
    Breadcrumb,
    OtherLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "รายละเอียดสิทธิพิเศษ",
          to: "/privilege"
        }
      ],
      privilegeDetail: [],
      isLoading: true,
      id: this.$route.params.privilegeId
    };
  },
  async created() {
    await this.getCouponDetail();
  },
  methods: {
    async getCouponDetail() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Privilege/coupon_detail/${this.id}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeDetail = data.detail.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  margin-bottom: 75px;
}
</style>
